import React from 'react';
import * as styles from './services.module.css';
import Image from '../../components/Image';
import Container from '../../components/Container';
import Layout from '../../components/Layout';
import { Link } from 'gatsby';

const ExercisePoolsPage = () => {
  return (<Layout disablePaddingBottom>
    <div className={styles.root}>
        <Container>
          <div className={styles.gridLayout}>
            <h1>Exercise Pools</h1>
                <p>
                  Pristine Pool & Spa is a factory trained installer (FTI) of Endless Pools®, the top-of-the-line exercise pool. 
                  An Endless Pool allows you to train, heal and swim with total freedom in your own backyard.
                  <br></br><br></br>
                  <a href="https://www.endlesspools.com/">Visit the Endless Pools website</a> to find the exercise pool that fits your needs, taste and lifestyle.
                  Contact Pristine Pool & Spa to discuss design and installation options.
                </p>
            </div>
            <section className={styles.imagesSection}>
              <Image height={480} src="/endlesspool2.jpeg" caption="10’ x 15’ Original Endless Pool installed by Pristine Pool & Spa."></Image>
              <Image className={styles.image} height={480} src="/exercise_pool_3.jpeg"></Image>
              <Image className={styles.image} height={380} src="/exercise_pool_2.jpeg"></Image>
              <Image className={styles.image} height={380} src="/exercise_pool_4.jpeg"></Image>
            </section>
        </Container>
    </div>
  </Layout>);
};

export default ExercisePoolsPage;